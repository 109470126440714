import React from 'react'
import { Parallax } from 'react-parallax'
import HeaderDetails from './HeaderDetails'
import './header.css'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logo } from 'assets/images';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';


const scrollToContact = () => {
    return scroller.scrollTo("learn-more", {
        duration: 500,
        hashSpy: true,
        delay: 100,
        smooth: true,
        offset:isMobile ? -60 : 0
    });
}

const Header = ({parallex,bgImage,smallTitle, title,titleTwo, buttonTitleOne, buttonTitleTwo, onButtonOne, onButtonTwo,imageStyle, small}) => {
    if(!parallex){
        return (
            <div>
                <div style={{height:small? '50vh':'100vh',backgroundAttachment:'fixed', transition:'0.3s',backgroundImage:`url(${bgImage})`, backgroundPosition:'left center', backgroundSize:'cover', display:'flex', flexDirection:'column',}}>
                    <HeaderDetails
                        smallTitle={smallTitle}
                        title={title}
                        titleTwo={titleTwo}
                        buttonTitleOne={buttonTitleOne}
                        buttonTitleTwo={buttonTitleTwo}
                        onButtonOne={onButtonOne}
                        onButtonTwo={onButtonTwo}
                    />
                </div>
            </div>
        )
    }
    return (
        <div>

            <Parallax bgImage={bgImage} strength={500} style={{ width: window.innerWidth, height:'100%', }} bgImageStyle={imageStyle}>
                <div style={{ height:small? '50vh':'100vh', display:'flex', flexDirection:'column',background:'linear-gradient(rgba(25, 25, 25, 33%), rgba(8, 8, 8, 33%))'}}> 
                    {!isMobile && <div style={{position:'absolute', top:40,left:70}}>
                        <Link to="/">
                            <img alt="Logo" src={logo} style={{ width:300, marginBottom: 10, marginTop:-10 }} />
                        </Link>
                    </div>}
                    <HeaderDetails
                            smallTitle={smallTitle}
                            title={title}
                            titleTwo={titleTwo}
                            buttonTitleOne={buttonTitleOne}
                            buttonTitleTwo={buttonTitleTwo}
                            onButtonOne={onButtonOne}
                            onButtonTwo={onButtonTwo}
                    />
                    <div style={{position:'absolute', bottom:isMobile? 150 : 25,width:'100%', flexDirection:'column', display:'flex'}}>
                        <div style={{margin:'auto'}}>
                            {/* <Link to="/">
                                <FontAwesomeIcon icon={faChevronDown} size="3x"/>
                            </Link> */}
                            <div id="arrow-down" onClick={scrollToContact}>
                                <div style={{marginBottom:-26}} onClick={scrollToContact}>
                                    <FontAwesomeIcon className="myarrow" icon={faChevronDown} color="#fff" style={{fontSize:40}}/>
                                </div>
                                <FontAwesomeIcon className="myarrow" icon={faChevronDown} color="#fff" style={{fontSize:40}} onClick={scrollToContact}/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </Parallax>
        </div>
    )
}

Header.defaultProps = {
    parallex: true
}
Header.propTypes = {
    bgImage: PropTypes.string.isRequired,
    smallTitle:PropTypes.string,
    title:PropTypes.string,
    titleTwo:PropTypes.string,
    buttonTitleOne:PropTypes.string,
    buttonTitleTwo:PropTypes.string,
    onButtonOne:PropTypes.func,
    onButtonTwo:PropTypes.func,
}


export default Header
