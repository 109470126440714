import React, { useEffect, useState } from 'react';
import { ColumnOne, ColumnTwo, ColumnThree, ColumnFour } from './Columns'
import { isMobile } from 'react-device-detect';
import { animateScroll } from 'react-scroll';
import ScrollUp from './ScrollUp';
const Footer = () => {

    const [isTop, setIsTop] = useState(true)

    const handleScroll = () => {
        if (window.scrollY < 100) {
           setIsTop(true)
        } else {
            setIsTop(false)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    return (
        <div style={{ paddingTop:isMobile? 20: 40, backgroundColor: '#000'}}>
             
            <div className="pb-3">
                <div className="col-md-10 mx-auto" >
                    <div className="row">
                        <ColumnOne />
                        <ColumnTwo />
                        <ColumnThree />
                        <ColumnFour />
                    </div>
                </div> 
            </div> 
            <div style={{ backgroundColor: '#FBE8A2'}} > 
                <div className="col-md-10 mx-auto pt-3 pb-3">    
                    <div className="row mt-auto">
                        <div className="col-md-6 defaiutl-bold ">
                            <div style={{ color: '#79611D', fontSize: 12}}>
                                © {new Date().getFullYear()}  <span style={{color:'#000'}}>GOLD SOLES HOLDINGS.</span> All Rights Reserved.
                            </div>
                        </div>
                        <div className="col-md-6 defaiutl-bold text-md-right">
                            <div style={{ color: '#79611D', fontSize: 12}}>
                                Developed By 
                                <span style={{color:'#000', marginLeft:5}}>
                                    <a className="ohtech-link" alt="" href="https://ohtech.co.za"  target="_blank" rel="noopener noreferrer">
                                        OpenHouse Technology
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollUp onClick={() => animateScroll.scrollToTop()} isTop={isTop}/>
        </div>
    )
}

export default Footer;