


import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const lending = "https://images.pexels.com/photos/259200/pexels-photo-259200.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
const investment = "https://images.pexels.com/photos/3833052/pexels-photo-3833052.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";
// const trading = "https://images.pexels.com/photos/159888/pexels-photo-159888.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500";
// const logistics = "https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"

const services = [
    {
        title:"Microloans",
        image:lending
    },
    // {
    //     title:"trading",
    //     image:trading
    // },
    {
        title:"investments",
        image:investment
    },
    // {
    //     title:"logistics",
    //     image:logistics
    // }
]
const ServiceCard = ({history}) => {
    const goTo = () => history.push('/services')
    return (
        <div className="row pt-5">
            <div className="col-md-3"/>
            {services.map((item, index) => (
                // <div className="col-md-3 mb-3 service-card">
                //     <div style={{height:250, backgroundImage:`url(${item.image})`, backgroundPosition:'center', backgroundSize:'cover', flexDirection:'column', display:'flex'}}>
                //         <div className="service-card-title-block" style={{width:'100%',padding:'20px 10px 10px 0px', backgroundColor:'#FBE8A2', marginTop:'auto'}}>
                //             <h5 style={{margin:0, color:'#79611D2'}}>{item.title}</h5>
                //         </div>
                //     </div>
                // </div>
                <div className="col-md-3" key={index}>
                    <div className="service-block-container" >
                        <div className="service-block" style={{backgroundImage:`url(${item.image})`, backgroundPosition:'center', backgroundSize:'cover',}}>
                            <div className="service-underlay">
                                <h5 className="service-name" onClick={isMobile ? goTo : null}>
                                    {item.title}
                                </h5>
                                <Link className="cta" to="/services">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="col-md-3"/>
        </div>
    )
}

export default withRouter(ServiceCard)
