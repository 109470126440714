import React from 'react'
import { Button } from 'components'
import '../header.css'

const HeaderDetails = ({smallTitle, title,titleTwo, buttonTitleOne, buttonTitleTwo, onButtonOne, onButtonTwo}) => {
    return (
        <div className="container" style={{margin:'auto 0px'}}>
        <div className="col-11 mx-auto">
            <h1 className="carousel-smaller-header" style={{color:'#fff', marginBottom:0}}>{smallTitle}</h1>
            <h1 className="carousel-header"  style={{lineHeight:'0.9em'}}>
                <span className="header-one" style={{color:'#DAA520',}}>{title}</span>
                <br/>
                <span className="header-two" style={{color:'#DAA520'}}>{titleTwo}</span>
            </h1>
            <div className="row">
            {buttonTitleOne &&<div className="col-md-auto col-12">
                     <Button 
                        type="secondary large" 
                        title={buttonTitleOne}  
                        onClick={onButtonOne}
                    />
                </div>}
                {buttonTitleTwo &&  <div className="col-md-auto col-12">
                    <Button 
                        type="primary large" 
                        title={buttonTitleTwo} 
                        onClick={onButtonTwo}
                    />
                </div>}
            </div>    
        </div>
    </div>
    )
}

export default HeaderDetails
