import React from 'react'
import Carousel from 'nuka-carousel'
import { faChevronLeft, faChevronRight, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';


const qoutes = [
    {
        qoute:"In the future the question will not be, are people credit worthy, but rather, are banks people worthy?",
        author:"Muhammad Yunus"
    },
    {
        qoute:"Microfinance is an idea whose time has come",
        author:"Kofi Annan"
    },
    {
        qoute:"The Grameen Bank has become a Mecca for development economists and is being copied around the world.",
        author:"The Economist"
    },
    {
        qoute:"Muhammad Yunus is a practical visionary who has improved the lives of millions of people in his native Bangladesh and elsewhere in the world. Banker to the poor [is] well-reasoned yet passionate.",
        author:"Los Angeles Times"
    },
    {
        qoute:"Microcredits has proved its worth among the poorest.",
        author:"The Economist"
    },
    {
        qoute:"Yunus shows that micro-lending can be much more effective than unwieldy and expensive aid programs.",
        author:"Publishers Weekly"
    },
    {
        qoute:"By giving poor people the wooer to help themselves, Dr Yunus has offered them something far more valuable than a plate of food —security in its most fundamental form.",
        author:"President Jimmy Carter"
    },
    {
        qoute:"Yunus’s ideas have already had a great impact on the Third World, and … hearing his appeal for a ‘poverty-free world’ from the source itself can be as stirring as that all-American myth of bootstrap success.",
        author:"The Washington Post"
    },
    {
        qoute:"I only wish every nation shared Dr. Yunus’s and the Grameen Bank’s appreciation of the vital role that women play in the economic, social and political life of our societies.",
        author:"Hillary Rodham Clinton"
    },
    {
        qoute:"An inspiring memoir of the birth of microcredit, written in a conversation	al tone that makes it both moving and enjoyable to read.",
        author:"Election2004.com"
    },
    {
        qoute:"Fascinating.",
        author:"Hungry Mind Review"
    },
    {
        qoute:"A fascination and compelling account by someone who decided to make a difference, and did.",
        author:"Choice"
    },
]
const Qoutes = () => {
    return (
        <div style={{backgroundColor:'#FBE8A2',}}>
        <div className="container mx-auto" style={{display:'flex', flexDirection:'column', height:'100%', paddingLeft:isMobile ? null :40}}>
            <Carousel
                style={{margin:'auto', display:'flex', flexDirection:'column'}}
                wrapAround
                renderCenterLeftControls={({ previousSlide }) => (
                    <div className="carouselButton" onClick={previousSlide}>
                        <span style={{ margin: 'auto' }}>
                            <FontAwesomeIcon icon={faChevronLeft} size="1x" color="#fff" />
                        </span>
                    </div>
                )}

                renderCenterRightControls={({ nextSlide }) => (
                    <div className="carouselButton" onClick={nextSlide}>
                        <span style={{ margin: 'auto' }}>
                            <FontAwesomeIcon icon={faChevronRight} size="1x" color="#fff" />
                        </span>
                    </div>
                )}
            >
                {qoutes.map((item, index) =>(
                    <div key={index} className="row" style={{margin:'auto', paddingTop:100, paddingBottom:50}}>
                    <div className="col-md-6 col-12 mx-auto" style={{margin:'auto', position:'relative'}}>
                       {!isMobile && <div className="left-qoute">
                            <FontAwesomeIcon className="left-qoute-icon" icon={faQuoteLeft} />
                        </div>}
                        <div className="quote"  style={{fontWeight:'900', textAlign:'center'}}>
                                {item.qoute}<div className="quote-author" style={{textAlign:'right',fontWeight:'bolder', fontSize:20, fontFamily:'Helvetic-main', marginTop:10}}>- {item.author}</div>
                        </div>
                        {!isMobile &&<div className="right-qoute">
                            <FontAwesomeIcon className="right-qoute-icon" icon={faQuoteRight} />
                        </div>}
                    </div>
                </div>
                ))}
            </Carousel> 
        </div>
    </div>
    )
}

export default Qoutes
