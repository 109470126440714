import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import MobileMenu from './MobileMenu';
import { isChrome, isIOS } from 'react-device-detect';
import PromptButton from '../PromptButton';


const links = [
    {
        name: "HOME",
        to:"/"
    },
    {
        name: "WHY GoldSole",
        to:"/about"
    },
    {
        name: "SERVICES",
        to:"/services"
    },
    {
        name:"WEB LOANS",
        to:"/web_loans"
    },
    {
        name: "CONTACT",
        to:"/contact"
    },
]
const NavigationBar = () => {
    const [open, setOpen] = useState(false);
    const [isOpen, setPromptOpen] = useState(false);

    const menuToggle = () => {
        setOpen(!open)
        // onMenu(!open)
    }

    const onGetLoan = () => {
       setPromptOpen(true)
       setOpen(!open)
    }

    if (window.innerWidth < 768) {
        return (
            
            <MobileMenu />
        )
    }
    return (
        <div style={{ zIndex: 1, position: 'relative',}}>
             <PromptButton isOpen={isOpen} onPrompt={() => setPromptOpen(false)}/>
            <div style={{ width: 40, height: '100vh', backgroundColor: '#79611D', position: 'fixed', cursor: 'pointer', zIndex: 9999, }} onClick={menuToggle} >
                <div className="menuButton" onClick={menuToggle} style={{ transition: "0.6s", }}>
                    {open ? " CLOSE" : "MENU"}
                </div>
            </div>
            <div style={{ height: '100vh', backgroundColor: '#79611D', transition: "0.6s", position: 'fixed', width:'100%', left:open?  0 : -window.innerWidth}}>
                <div className="row no-gutter">
                    <div className="col-md-auto no-gutter" style={{ backgroundColor: '#79611D', height: '100vh',width:40 }}>
                    </div>
                    <div className="col-md-4" style={{ backgroundColor: '#000', height: '100vh',color:'#fff',padding:"20px 0px 0px 60px",display:'flex', flexDirection:'column' }}>
                        <div style={{marginLeft:-45}}>
                            <Link to="/" >
                                {/* <img alt="gold soles logo" src={logo} style={{ height: 80 }} /> */}
                            </Link>
                        </div>
                        <div style={{ margin: 'auto 0px', display:open ? "initial" : 'none', transition:0.1}}>
                            <ul style={{listStyle:'none', marginLeft:-40}}>
                                {links.map((item, index) =>{
                                    const isSite = item.name === "WEB LOANS" && (!isChrome || isIOS )
                                    return (
                                        <li key={index}> 
                                            <h5 className="menuLink">
                                               {!isSite && <Link  onClick={menuToggle} to={item.to} >{item.name}</Link>}
                                               {isSite && <div onClick={onGetLoan}>sdfdfdsfsd</div>}
                                            </h5>
                                        </li>
                                    )
                                })}
                           </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default NavigationBar