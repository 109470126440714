import React, { Component } from 'react';
import { animateScroll, scroller } from "react-scroll";
import { Header, Loader, NavigationBar, Footer, HeaderStrip, SeoHeader, Qoutes } from 'components';
import { isMobile } from 'react-device-detect';
// import { images } from 'assets';
import ReactGA from 'react-ga';

const bgImage = "https://images.pexels.com/photos/584179/pexels-photo-584179.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";

class About extends Component {

    state = {
        loading:true
    }

    componentDidMount = async () =>{
        ReactGA.initialize('UA-135086987-6');
        ReactGA.pageview(window.location.pathname);
        animateScroll.scrollToTop();
        this.setState({loading:false})
    }


    scrollToContact = () => {
        return scroller.scrollTo("learn-more", {
            duration: 500,
            hashSpy: true,
            delay: 100,
            smooth: true,
            offset:isMobile ? -60 : 0
        });
    }

    render() {
        return (
            <Loader loading={this.state.loading}>
                <SeoHeader 
                    title="About Us"
                    description={`
                        Established in 2015, Gold Soles’ is an investment consortium 
                        founded by four young female professionals with the vision of making access to finance cheaper, fast, convenient and fair.
                    `}
                    keywords="Investments, Logistics, Goldsoles, Lending, Trading, Who we are, why Goldsoles"
                />
                <NavigationBar/>
                <Header
                    bgImage={bgImage}
                    smallTitle="Why"
                    title="GoldSoles"
                    // titleTwo="GoldSole"s
                    buttonTitleOne="Learn more"
                    onButtonOne={this.scrollToContact}
                    buttonTitleTwo="contact us"
                    onButtonTwo={() =>  this.props.history.push('contact')}
                />
                <section>
                    <HeaderStrip 
                        title="About Us"
                        description={`
                            The Name Gold Soles is a celebration of all elements of femininity and success
                        `}
                    />
                    <div className="container pt-5 pb-5">
                        <div className="col-md-12 pb-5">
                            <h1>Who Are We</h1>
                            <div>
                                Established in 2015, Gold Soles’ is an investment consortium founded 
by four young professionals with the vision of making access to 
                                finance cheaper, fast, convenient and fair.<br/>
                                The consortium aims to establish and develop itself as an alternative, 
                                preferable and safe financial solutions platform with the ultimate 
                                goal of becoming a powerhouse of venture investors. 
                                <br/><br/>
                                The Name Gold Soles is a celebration of all elements of 
                                femininity and success.
                            </div>
                        </div>
                        <hr/>
                        <div className="row mx-auto pt-5">
                            <div className="col-md-4 mb-2">
                                <div style={{height:200,padding:'0px 30px', width:'100%', backgroundColor:'#000',display:'flex', flexDirection:'column'}}>
                                    <div style={{ margin:'auto', textAlign:'center'}}>
                                        <h4 style={{color:'#79611D'}}>We are convenient.</h4>
                                        <p style={{color:'#fff'}}>
                                            Faster turn around times
                                            than traditional banks at your
                                            finger tips.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div style={{height:200,padding:'0px 30px', width:'100%', backgroundColor:'#000',display:'flex', flexDirection:'column'}}>
                                    <div style={{ margin:'auto', textAlign:'center'}}>
                                        <h4 style={{color:'#79611D'}}>We are people centered.</h4>
                                        <p style={{color:'#fff'}}>
                                            Financing people based on their unique circumstances.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div style={{height:200,padding:'0px 30px', width:'100%', backgroundColor:'#000',display:'flex', flexDirection:'column'}}>
                                    <div style={{ margin:'auto', textAlign:'center'}}>
                                        <h4 style={{color:'#79611D'}}>We are innovative.</h4>
                                        <p style={{color:'#fff'}}>
                                        Credit solutions and structures for the 21st century.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    <Qoutes />
                </section>
                <Footer/>
            </Loader>
        )
    }
}

export default About;
