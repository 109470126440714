import React from 'react'
import { Helmet } from "react-helmet";

function SeoHeader({ title,url,description, keywords}) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>GoldSoles | {title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    )
}

export default SeoHeader
