import React, {Component} from 'react'
import {Button} from 'components'
import { isMobile, isTablet } from 'react-device-detect';
import { SEND_EMAIL } from './send_email';
import { withApollo } from 'react-apollo';
import { images } from 'assets';
import {  ClipLoader } from "react-spinners";


const imageStyle = {
    backgroundImage: `url(${images.contact})`, backgroundSize: 'contain', backgroundPosition: 'center',
    backgroundRepeat: "no-repeat",
}

const defaultState = {
    name: undefined,
    surname: undefined,
    email: undefined,
    phone: undefined,
    comment: undefined,
    topic: undefined,
}

var emailregex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ContactUs extends Component {
    state = {
        ...defaultState,
        buttonLoading:false
    }

    send = async () => {
        const {  name,surname, phone,email , comment, topic} = this.state
        const isValid = this.isValid(name) && this.isValid(surname) &&this.isValid(phone) && emailregex.test(email)
        try {
            if (isValid) {
                this.setState({buttonLoading:true})
                const input = {
                    email:email,
                    phone:phone,
                    service:topic,
                    to:"enquiries@goldsoles.co.za",
                    fullname:name+" "+surname,
                    comment:comment,
                    website:"goldsoles.co.za"
                  }
                const response = await  this.props.client.mutate({
                    mutation: SEND_EMAIL,
                    variables:{
                        input :input
                    }
                })
                if(response){
                     return this.setState({
                        name: "",
                        surname: "",
                        email: "",
                        phone: "",
                        service:"",
                        comment: "",
                        buttonLoading:false,
                        message: "Thank you for making contact. Our team will be in touch soon."
                    }, ()=>{
                        this.setState({...defaultState,})
                    })
                }
                return this.setState({
                    buttonLoading:false,
                    message: `<span style="color:red">Ops something went wrong. Please Try Again Later!</span>`
                })
            }
            return this.setState({
                message: `<span style="color:red">Please fill in all the required fields</span>`
            })
            } catch (error) {
                this.setState({ ...defaultState})
            }
        
    }

    
    onChangeHandler = (event) => {
        console.log(event.target.name, event.target.value)
        this.setState({
            [event.target.name]: event.target.value,
              message:null
        })
    }

    isValid = (value) => {
        if (value === "" || !value) {
           return false
        }
        return true
    }


    valid = (value) => {
        if (value === "") {
            return '1px solid red'
        }
        else if(value === undefined){
            return '1px solid #ccc'
        }
        return '1px solid green'
    }
       

    render() {
        const { company, name, phone, email,surname,buttonLoading, comment, message,topic} = this.state
        const emailValid = email === undefined ? '1px solid #ccc' : (emailregex.test(email) ? '1px solid green' : '1px solid red');
        const isValid = this.isValid(company) && this.isValid(surname) && this.isValid(name) && this.isValid(phone) && emailregex.test(email) && this.isValid(topic)
        return (
            <div className="container pt-5" id="contact-us">
                <div className="row min-vh-30">
                    <div className="col-md-12 ml-auto">
                        <div className="row">
                            <div className="col-md-8 mx-auto ">
                                <div style={{ marginTop: 30, textAlign: isMobile ? 'center' : 'left', }}>
                                    <h1>GET IN TOUCH</h1>
                                </div>
                                <div style={{ marginTop: 10, textAlign: isMobile ? 'center' : 'left',  }}>
                                    <h5 style={{ textTransform: 'uppercase' }}>Lets have a chat!</h5>
                                    <div>
                                        Please fill in the contact form and we will contact you as soon as possible.
                                    </div>
                                </div>
                               
                                <div style={{ marginTop: 30, marginBottom: 30 }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="text"
                                                name="name"
                                                value={name}
                                                onChange={this.onChangeHandler}
                                                placeholder="Name *"
                                                style={{ width: '100%', height: 50 ,backgroundColor: '#fff', border: this.valid(name),paddingLeft: 20, marginTop: 10, marginBottom: 10 }} />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text"
                                                name="surname"
                                                value={surname}
                                                onChange={this.onChangeHandler}
                                                placeholder="Surname *"
                                                style={{ width: '100%', height: 50 ,backgroundColor: '#fff', border: this.valid(surname),paddingLeft: 20, marginTop: 10, marginBottom: 10 }} />
                                        </div>
                                    </div>
                                    <input type="text"
                                        value={email}
                                        onChange={this.onChangeHandler}
                                        placeholder="Email*"
                                        name="email"
                                        style={{ width: '100%', height: 50, backgroundColor: '#fff', border: emailValid, paddingLeft: 20, marginTop: 10, marginBottom: 10 }} />
                                    <select 
                                        name="topic"
                                        onChange={this.onChangeHandler}
                                        style={{ width: '100%', height: 50, backgroundColor: '#fff', border: emailValid, paddingLeft: 20, marginTop: 10, marginBottom: 10 }} >
                                        <option value="" disabled selected>Enquiry Topic</option>
                                        <option value="Personal Finance">Personal Finance</option>
                                        <option value="Manage my debt">Manage my debt</option>
                                        <option valkue="Fund my business">Fund my business</option>
                                    </select>
                                    <input type="text"
                                        value={phone}
                                        onChange={this.onChangeHandler}
                                        name="phone"
                                        placeholder="Preferred Contact Number *"
                                        style={{ width: '100%', height: 50, backgroundColor: '#fff', border: this.valid(phone), paddingLeft: 20, marginTop: 10, marginBottom: 10 }} />
                                   
                                    <textarea
                                        name="comment"
                                        value={comment}
                                        onChange={this.onChangeHandler}
                                        placeholder="Additional Comments"
                                        style={{ width: '100%', height: 130, backgroundColor: '#fff',paddingTop: 10, paddingLeft: 20, marginTop: 10, border:'1px solid #ccc'}} 
                                    />
                                    <div style={{ transition: "0.3s", margin: message ? "10px 0px" : null, fontSize: 18 }} dangerouslySetInnerHTML={{
                                        __html: message}}></div>
                                    <div className="mt-2">
                                        {buttonLoading ? 
                                            <ClipLoader
                                                size={30}
                                                color={"#79611D"}
                                            />:
                                        <div style={{ marginTop: 10 }}>
                                            <Button title="submit" type="secondary large" onClick={this.send} className="contact-us" disabled={!isValid}/>
                                        </div>}
                                    </div>
                                </div>
                                
                            </div>
                            {(!isMobile || isTablet) && <div className="col-md-12 nopadding" style={{ ...imageStyle, padding: 0 }}>

                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default withApollo(ContactUs)
