import React from "react";
import { ClipLoader } from "react-spinners";
function Loader({ loading, children }) {
  if (!loading) return children;
  return (
    <div
      style={{
        height: "100vh",
        flexDirection: "column",
        display: "flex",
        backgroundColor: "#000",
      }}
    >
      <div style={{ margin: "auto" }}>
        <ClipLoader size={100} color={"#79611D"} />
        <h5 style={{ color: "#fff", marginTop: 50, textAlign: "center" }}>
          Loading ...
        </h5>
      </div>
    </div>
  );
}

export default Loader;
