import React from 'react';
import { isMobile } from 'react-device-detect';

const ColumnThree = () => {
    return (
        <div className="col-md-3" style={{ color: '#fff', }}>
            <h5 style={{ color: '#fff', textTransform: 'uppercase', fontWeight: 'bold',  fontSize: 15, marginTop: isMobile ? 30 : 0 }}>Your questions and comments</h5>
               
                <div style={{ marginTop: isMobile ? 20 : 20, fontSize: 14 }}>
                <div style={{ marginBottom: 10 }}>For Enquiries please contact us on the following emails:</div>
                <div style={{ marginBottom: 10 }}><b style={{marginRight:5}}>Customer Related:</b> loans@goldsoles.co.za</div>
                <div style={{ marginBottom: 10 }}><b style={{marginRight:5}}>Repayment or Existing Client:</b> accounts@goldsoles.co.za</div>
                <div style={{ marginBottom: 10 }}><b style={{marginRight:5}}>General enquiries:</b> enquiries@goldsoles.co.za</div>
            </div>
        </div>
    )
}

export default ColumnThree
