import React from 'react'
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLinkedin,faFacebook,faTwitter} from '@fortawesome/free-brands-svg-icons';

const social = [
    {
        name: "facebook",
        link: "https://www.facebook.com/goldsolesSA",
        icon:faFacebook
    },

    {
        name: "twitter",
        link: "https://twitter.com/gold_soles?s=08",
        icon: faTwitter
    },
    {
        name: "linkedin",
        link: "https://www.linkedin.com/company/goldsoles",
        icon: faLinkedin
    },
]

const ColumnFour = () => {
    return (
        <div className="col-md-2" style={{ color: '#fff', }}>
            <h5 style={{ color: '#fff', textTransform: 'uppercase', fontWeight: 'bold',  fontSize: 15, marginTop: isMobile ? 30 : 0 }}>follow us</h5>
                <div style={{ marginTop: isMobile ? 20 : 40}}>
                <ul style={{ listStyle: 'none', marginLeft: -40, display: 'flex', marginTop: 20 }}>
                {social.map((data, index) => (
                    <li key={index} style={{ marginRight: 20 }} className="social-icons">
                        <a href={data.link} target="_blank" rel="noopener noreferrer" >
                            <FontAwesomeIcon className="my-icons" icon={data.icon}  color="#fff" size="lg"/>
                        </a>
                    </li>
                ))}
                </ul>
            </div>
        </div>
    )
}

export default ColumnFour