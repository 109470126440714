import React from 'react'
import { logo } from 'assets/images'
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const ColumnFour = () => {
    return (
        <div className="col-md-4" style={{ color: '#fff'}}>
            <div style={{marginTop:isMobile ? 20 : 0}}>
              <Link to="/">
                  <img alt="openhouse Logo" src={logo} style={{ width: '70%', marginBottom: 10, marginTop:-10 }} />
              </Link>
            </div>
            <div style={{ marginBottom: 15, fontSize: 14}}>
            Gold Soles Holdings is a Registered Credit Provider with 
            the National Credit Regulator of South Africa as well as 
            with the Zululand Chamber of Commerce and Industries.
            </div>
            <div style={{ fontSize: 14}}>
                Get hold of the GS team on <b> 076 607 0995 </b> <br/>From 08:00 - 18:00 Monday till Friday.<br/>08:00- 15:00 on weekends.
            </div>
           
        </div>
    )
}

export default ColumnFour