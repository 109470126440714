import React from 'react'
import './button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types';

const Button = ({ title, onClick, type, leftIcon, rightIcon, value }) => {
    return (
        <button value={value} className={`myButton ${type}`} onClick={onClick}>
            {leftIcon && <span style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faChevronRight} size="sm" color={type === "primary" ? "#000" : ""} />
            </span>}
            {title}
            {rightIcon && <span style={{ marginLeft: 15, marginTop: 2 }}>
                <FontAwesomeIcon icon={rightIcon} size="sm" />
            </span>}
        </button>
    )
}


Button.defaultProps = {
    leftIcon: faChevronRight
}
Button.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    leftIcon: PropTypes.any,
    rightIcon: PropTypes.any,
    value: PropTypes.any,
}

export default  Button;