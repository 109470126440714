import React, { Component } from "react";
import MenuLink from './MenuLink';
import { logo,  } from 'assets/images';
import { Link } from 'react-router-dom';
export default class MobileMenu extends Component {
  state = {
    isOpen: false
  };

  onMenu = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { isOpen } = this.state;
    const Width = isOpen ? "100%" : 0;
    return (
      <div style={{ width: "100%", position: "fixed", top: 0, zIndex: 48 }}>
        <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#000", height: 60, borderColor: "#000" }}
          >
            
            <div className="col-10 text-center align-self-center">
              <Link to="/">
                <img
                  alt="logo"
                  className="App-logo"
                  src={logo}
                  style={{ height: 40 }}
                />
              </Link>
            </div>
            <div className="col-2 align-self-right">
              {isOpen ? (
                <span
                  style={{ fontSize: 40, cursor: "pointer", color: "#fff" }}
                  onClick={this.onMenu}
                >
                  &times;
                </span>
              ) : (
                <span
                  style={{ fontSize: 30, cursor: "pointer", color: "#fff" }}
                  onClick={this.onMenu}
                >
                  &#9776;
                </span>
              )}
            </div>
          </div>
        </div>
        <div id="mySidenav" className="sidenav" style={{ width: Width, maxWidth:500, paddingTop:0,borderTop:'1px solid #2c2c2d' }}>
            <MenuLink title="HOME" link="/" isOpen={isOpen} onLink={this.onMenu} />
            <MenuLink title="WHY GOLDSOLE" link="/about" isOpen={isOpen} onLink={this.onMenu}/>
            <MenuLink title="SERVICES" link="/services" isOpen={isOpen} onLink={this.onMenu}/>
            <MenuLink title="CONTACT" link="/contact" isOpen={isOpen} onLink={this.onMenu}/>  
        </div>
      </div>
    );
  }
}
