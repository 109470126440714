import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Home, Contact, Services, About, WebLoans } from "./pages";
import { isIOS, isChrome } from "react-device-detect";

export default (
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/about" exact component={About} />
      <Route path="/services" exact component={Services} />
      {!isIOS && isChrome && (
        <Route path="/web_loans" exact component={WebLoans} />
      )}
      <Route component={Home} />
    </Switch>
  </Router>
);
