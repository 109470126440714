import React, { Component } from 'react';
import { animateScroll, scroller } from "react-scroll";
import { Header, Loader, NavigationBar, Footer, HeaderStrip, SeoHeader, Button, Qoutes, PromptButton } from 'components';
import { isMobile, isChrome, isIOS } from 'react-device-detect';
import ReactGA from 'react-ga';

const bgImage = "https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";


const Lending = "https://images.pexels.com/photos/259200/pexels-photo-259200.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
const investment = "https://images.pexels.com/photos/3833052/pexels-photo-3833052.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";



const isCompatible = !isIOS && isChrome;
class Services extends Component {
    state = {
        loading:true,
        isOnpen:false
    }
    
    componentDidMount = async () =>{
        ReactGA.initialize('UA-135086987-6');
        ReactGA.pageview(window.location.pathname);
        animateScroll.scrollToTop();
        this.setState({loading:false})
    }

    scrollToContact = () => {
        return scroller.scrollTo("learn-more", {
            duration: 500,
            hashSpy: true,
            delay: 100,
            smooth: true,
            offset:isMobile ? -60 : 0
        });
    }

    onGetLoan = () => {
        if(isCompatible){
            return this.props.history.push('/web_loans')
        }
        return this.setState({isOpen:true})
    }

    
    render() {
        return (
            <Loader loading={this.state.loading}>
                  <PromptButton isOpen={this.state.isOpen} onPrompt={() => this.setState({isOpen:false})}/>
                <SeoHeader
                    title="Services - What We Offer"
                    description={`
                        We aim to achieve our objectives through the 
                        services that we proudly provide.
                    `}
                    keywords="Investments, Logistics, Lending, Trading, Who we are, why Goldsoles, Goldsoles"
                />
                <NavigationBar/>
                <Header
                    bgImage={bgImage}
                    smallTitle="Our"
                    title="Services"
                    buttonTitleOne="Learn more"
                    onButtonOne={this.scrollToContact}
                    buttonTitleTwo="contact us"
                    onButtonTwo={() =>  this.props.history.push('contact')}
                />
                <section>
                    <HeaderStrip 
                        title="Services"
                        description={`
                            We aim to achieve our objectives through the 
                            services that we proudly provide.
                        `}
                    />
                  
                        <div className="container pt-5 pb-5">
                            <div className="row mx-auto">
                                <div className="col-md-6 mb-5 text-md-right">
                                    <div style={{margin:'0px'}}>
                                        <h1 className="mb-2">Microloans</h1>
                                        <div>
                                            Micro Lending- we offer short term loans to individuals at competitive flat rates<br/><br/>
                                            Macro Lending- we offer flexible and innovative financing to small businesses and start ups.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2" style={{display:'flex', flexDirection:'column',}}>
                                    <div style={{margin:'0px'}}>
                                        <h1 className="mb-2">Investments</h1>
                                        <div>
                                            Gold Soles Seeks to Empower SMMEs by converting portions of 
                                            these macro Loans into investements and 
                                            fostering mutually beneficial business relationships
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-auto">
                                <div className="col-md-6 mb-5">
                                    <div style={{background:`url(${Lending})`,backgroundSize:'cover', backgroundPosition:'center', minHeight:300}}>

                                    </div>
                                </div>
                                <div className="col-md-6 mb-5" style={{display:'flex', flexDirection:'column',}}>
                                    <div style={{background:`url(${investment})`,backgroundSize:'cover', backgroundPosition:'center', minHeight:300}}>

                                    </div>
                                </div>
                            </div>
                            <div className="row mx-auto mb-4">
                                <div className="col-md-8 col-12 text-center mx-auto">
                                    The team at Goldsoles has been committed to establishing and maintaining relationships with our clientele,  as they embark on their journeys to fulfill their dreams.
                                </div>
                            </div>
                            <div className="container text-center">
                                <div style={{ marginTop: 10, textAlign:'center' }}>
                                   
                                    <Button title="Get A Loan" type="secondary large" onClick={this.onGetLoan} className="contact-us"/>
                                </div>
                            </div>
                        </div>

                         {/* <Parallax bgImage={investImage} strength={isMobile ? 0 : 500} style={{ width: window.innerWidth, height: isMobile ? 300 : '40vh'}} >
                        </Parallax> */}
                        <Qoutes />
                </section>
                <Footer/>
            </Loader>
        )
    }
}

export default Services;