import React from 'react'
import { Link,withRouter } from 'react-router-dom'

const MenuLink = ({title, link,isOpen,onLink, match, location, history}) => {
    const isActive = match.path === link 
    const AactiveColor = isActive ? "#79611D" :  '#fff'
    return (
        <div
          className="mobile-menu-container"
          style={{ backgroundColor:'#000' }}
          onClick={onLink}
        >
          <Link to={link} style={{padding:0, margin:0}}>
            <div
              className="row"
              style={{ height: "auto"}}
              
            >
              <div className="col-12 text-left">
                <span style={{ color:AactiveColor,fontSize:isOpen ?14 :2 , transition:'0.5s' }}>
                  {title}
                </span>
              </div>
              {/* <div className="col-6 text-right" >
              </div> */}
            </div>
          </Link>
        </div>
    )
}

export default withRouter(MenuLink)
