import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

const ScrollUp = ({onClick, isTop}) => {
    return (
        <div className="scroller-to-top" onClick={onClick} style={{ bottom: isTop? -100 :70 }}>
                <div style={{ margin: 'auto' }}>
                    <FontAwesomeIcon icon={faChevronUp} color="#fff" />
                </div>
            </div>
    )
}

export default ScrollUp
