import React, { Component } from "react";
import ServiceCard from "./ServicesCard/index";
import "./services.css";
import {
  Header,
  ContactUs,
  Loader,
  NavigationBar,
  Footer,
  SeoHeader,
  Qoutes,
  Button,
  PromptButton,
} from "components";
import { animateScroll, scroller } from "react-scroll";
import { isMobile, isChrome, isIOS } from "react-device-detect";
import ReactGA from "react-ga";

const bgImage =
  "https://images.unsplash.com/photo-1567427017947-545c5f8d16ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1595&q=80";
const isCompatible = !isIOS && isChrome;

class Home extends Component {
  state = {
    loading: true,
    isOpen: false,
  };
  componentDidMount = async () => {
    ReactGA.initialize("UA-135086987-6");
    ReactGA.pageview(window.location.pathname);
    animateScroll.scrollToTop();
    this.setState({ loading: false });
  };

  scrollToContact = () => {
    return scroller.scrollTo("learn-more", {
      duration: 500,
      hashSpy: true,
      delay: 100,
      smooth: true,
      offset: isMobile ? -60 : 0,
    });
  };

  onGetLoan = () => {
    if (isCompatible) {
      return this.props.history.push("/web_loans");
    }
    return this.setState({ isOpen: true });
  };

  render() {
    return (
      <Loader loading={this.state.loading}>
        <PromptButton
          isOpen={this.state.isOpen}
          onPrompt={() => this.setState({ isOpen: false })}
        />
        <SeoHeader
          title="Investment | Logistics"
          description={`
                        Gold Soles Holdings is a young black female owned financial and 
                        investment services firm that operates 
                        in the Micro Lending  and the logistics Industries.
                    `}
          keywords="Investments, Logistics, Goldsoles, Lending, Trading, Who we are, why Goldsoles"
        />
        <NavigationBar />
        <Header
          bgImage={bgImage}
          smallTitle="Welcome To"
          title="GOLD SOLES"
          titleTwo="Holdings"
          buttonTitleOne="Learn more"
          onButtonOne={this.scrollToContact}
          buttonTitleTwo="contact us"
          onButtonTwo={() => this.props.history.push("contact")}
        />
        <section>
          <div
            id="learn-more"
            className="container pt-5 pb-5 mb-5 mt-5 text-left"
          >
            <h1>About Gold Soles</h1>
            <div className="">
              Gold Soles Holdings is a black female founded micro loan and
              investment business which aims to establish itself as an
              alternative, preferable, accessible and safe financial solutions
              provider; servicing the entire fabric of society. Our mission is
              to provide financial assistance in conjunction with basic
              financial literacy, to achieve our ultimate goal; to create
              fishermen of financial freedom in societies across South Africa.
              <br />
              <br />
              Based in Richards Bay, our services reach all parts of South
              Arica.
              <br />
              <br />
              Gold Soles Holdings is a registered entity with the National
              Credit Regulator, and a member of the Zululand Chamber of
              Industries.
            </div>
          </div>
          <hr className="container" />
          <div className="container pt-5 pb-5 mb-5 mt-5 text-left">
            <h1>OUR SERVICES</h1>
            <div className="">
              Goldsoles services encompass our micro-loaning structure, and the
              part investments structure where a portion of the loan is
              converted to equity for small business, in line with our strategic
              objectives.
            </div>
            <ServiceCard />
            <div
              className="text-center"
              style={{ marginTop: 20, marginBottom: -60 }}
            >
              <Button
                title="Get A Loan"
                type="secondary large"
                onClick={this.onGetLoan}
                className="contact-us"
              />
            </div>
          </div>

          <div
            className="pb-2 pt-2"
            style={{ backgroundColor: "#fff", fontWeight: "bold" }}
          >
            <h1 className="text-center mt-5 mb-0">Zero to Gold</h1>
            <div
              className="col-md-9 mx-auto text-center "
              style={{ height: "100%", width: "100%", fontWeight: "bold" }}
            >
              <h5 style={{ fontSize: 18 }}>
                {" "}
                A simple guide to financial savviness
              </h5>
              <br />
              <div className="col-md-9 col-12 text-center mx-auto mb-3">
                Before we can help you, we have compiled an easy to follow
                action by action financial guide to help you, help yourself, and
                kickstart your journey from zero credit to gold bar status.
              </div>
            </div>

            <div className="container pb-5 mb-5">
              <div className="row">
                <div
                  className="col-md-auto col-12 pt-md-3 pb-md-3"
                  style={{ width: isMobile ? "100%" : "32%" }}
                >
                  <div className="text-md-right">
                    <ul
                      className="money-tips-list"
                      style={{ fontSize: 16, paddingLeft: isMobile ? 20 : 0 }}
                    >
                      <li style={{ marginBottom: 10 }}>Make a budget</li>
                      <li style={{ marginBottom: 10 }}>
                        Set short term financial goals{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Always go shopping with a list{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Track your spending every month Budget versus actual{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Compete with someone else to save more{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Check your credit score!!!!!
                        <br />
                        Good credit = good interest rates.{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Play hard to get with your money- keep as little money
                        as possible in your everyday checking account, with the
                        rest to be kept in a harder-to-reach savings account.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-md-auto col-12"
                  style={{
                    minHeight: 200,
                    background: `url(${"https://www.incorp.asia/wp-content/uploads/2019/01/Achieve-Your-Financial-Goals-800x400.jpg"})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "36%",
                  }}
                ></div>
                <div
                  className="col-md-auto col-12 pt-md-3 pb-3 text-md-left"
                  style={{ width: isMobile ? "100%" : "32%" }}
                >
                  <div className="">
                    <ul
                      className="money-tips-list"
                      style={{ paddingLeft: isMobile ? 20 : 0, fontSize: 16 }}
                    >
                      <li style={{ marginBottom: 10 }}>
                        Cut your subscriptions- internet, cell contracts, pay
                        TV.
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Work on your financial education- you have started in
                        the right place{" "}
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Pretend you didn’t get a raise- keep your expenses
                        steady with rising income.
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Don’t keep up with the Joneses. Dave Ramsey once said:
                        “We buy things we don’t need with money we don’t have to
                        impress people we don’t like.”
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Open an account for emergency funds only.
                      </li>
                      <li style={{ marginBottom: 10 }}>
                        Change to a no-fee bank account.
                      </li>
                      <li style={{ marginBottom: 10 }}>Join a stokvel.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Parallax bgImage={images.ladies} strength={isMobile ? 0 : 500} style={{ width: window.innerWidth, height: isMobile ? 300 : '50vh'}} bgImageStyle={{bottom:isMobile ? 0 : -500}}>
                    </Parallax>
                    
                    
                    <Video /> */}
          <ContactUs />
          <Qoutes />
        </section>
        <Footer />
      </Loader>
    );
  }
}

export default Home;
