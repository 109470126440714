import React from 'react'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const webloan_url = "https://2870-8888.webfin.co.za/WEBLOANS"
const PromptButton = ({onPrompt, isOpen}) => {


    if(!isOpen) return null
    return (
        <div className="row mx-auto no-gutters" style={{display:'flex',height:'100vh', width:'100%', position:'fixed', backgroundColor:'rgba(0,0,0,0.7)', zIndex:99}}>
            <div className="col-md-6 col-11 mx-auto bg-light p-0 p-5 pb-3 mt-auto mb-auto text-center position-relative">
                <div class="link" style={{position:'absolute', top:10, right:10, width:30, height:30}} onClick={onPrompt}>
                    <FontAwesomeIcon icon={faTimes} size="lg"/>
                </div>
                <div className="text-center mt-4">
                    <h4>Please Note !</h4>
                    <p style={{fontSize:20}}> 
                        To assure you a secure process, we are about to be redirected 
                        you to a different web page in order to proceed with your loan application.
                        <br/><strong>Thank You.</strong>
                    </p>
                </div>
                <Button 
                    className="contact-us"
                    leftIcon="" title="Proceed" type="secondary large" 
                    onClick={() => { onPrompt(); return window.open(webloan_url)}} 
               />
            </div>
        </div>
    )
}

export default PromptButton
