import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import {withRouter} from 'react-router-dom'

const Strip = ({title, description,history,routing}) => {
    return (
        <div>
             <div className="col-md-10 mx-auto" >
                        <div id="learn-more" style={{ borderBottom: '1px solid #C5C6C6', padding: "15px 0px" }}>
                            <div style={{ height: '100%', width: '100%', textTransform:'uppercase' }}>
                                <ul style={{display:'inline-block', marginBottom:0}}>
                                   {routing && <li style={{display:'inline-block'}}>
                                        <span 
                                            style={{ cursor:"pointer",width:30, height:30,display:'flex', flexDirection:"column", backgroundColor: '#79611D', marginRight: 10, color: '#fff' }} 
                                            onClick={() => history.goBack()}
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} style={{margin:'auto'}}/>
                                        </span>
                                    </li>}
                                    {!routing && <li style={{display:'inline-block'}}>
                                        <span 
                                            style={{width:30, height:30,display:'flex', flexDirection:"column", backgroundColor: '#79611D', marginRight: 10, color: '#fff' }} 
                                        >
                                            <FontAwesomeIcon icon={faTimes} style={{margin:'auto'}}/>
                                        </span>
                                    </li>}
                                    <li style={{display:'inline-block'}}>
                                        <div style={{ fontWeight:'bolder'}}>{title}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 40, paddingBottom: 40, backgroundColor:'#F3F3F3'}}>
                       <div className="col-md-9 mx-auto" style={{ height: '100%', width: '100%' }}>
                           {description}
                       </div>
                    </div>
        </div>
    )
}

Strip.defaultProps = {
    routing: true
}
Strip.propTypes = {
 
}

const HeaderStrip = withRouter(Strip)
export  {HeaderStrip}
