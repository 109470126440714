import React, { Component } from 'react'
import { animateScroll,scroller } from "react-scroll";
import { Header, HeaderStrip, NavigationBar, Footer, Loader, ContactUs, SeoHeader, Qoutes } from 'components';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

const bgImage = "https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80";
const mapAddress = "https://maps.google.com/maps?q=24%20Gallinule%20Gait%2C%20Richardsbay&t=&z=13&ie=UTF8&iwloc=&output=embed"
class About extends Component {
    state = {
        loading:true
    }

    componentDidMount = async () =>{
        ReactGA.initialize('UA-135086987-6');
        ReactGA.pageview(window.location.pathname);
        animateScroll.scrollToTop();
        this.setState({loading:false})
    }

    scrollToContact = () => {
        return scroller.scrollTo("contact-us", {
            duration: 500,
            hashSpy: true,
            delay: 100,
            smooth: true,
            offset:isMobile ? -60 : 0
        });
    }

    render() {
        const mapWidth = isMobile ? window.innerWidth : window.innerWidth-40;
        return (
            <Loader loading={this.state.loading}>
                <SeoHeader 
                    title="Contact Us"
                    description={`
                        Keep in touch, find a business contact or scroll down to use our online contact form.
                    `}
                    keywords="Keep in Touch, Contact, Goldsoles, Investment"
                />
                <NavigationBar/>
                <Header
                    bgImage={bgImage}
                    smallTitle="GET"
                    title="IN TOUCH"
                    // titleTwo="GoldSole"
                    // buttonTitleOne="Learn more"
                    buttonTitleTwo="contact us"
                    onButtonTwo={this.scrollToContact}
                    imageStyle={{left:isMobile? '100%': '50%'}}
                />
                 <section>
                    <HeaderStrip 
                        title="Contact Us"
                        description={`Leave us a message we would love to hear from you.`}
                    />
                    {/* <div className="container pt-5 pb-5 mb-5 mt-5 text-left">
                        <h1>About Gold Soles</h1>
                        <div className="">
                            Gold Soles Holdings is a young black female owned financial and investment services firm that 
                            operates in the Micro Lending  and the logistics Industries.
                            We are a Registered Credit Provider with the National Credit Regulator of South Africa as 
                            well as with the Zululand Chamber of Commerce and Industries.<br/><br/>
                            Based in Richards Bay, in Northern KZN, Gold Soles services reach all parts of beautiful South Africa.
                        </div>
                    </div> */}
                    <div className="mapouter" style={{ width:mapWidth, paddingLeft:isMobile? 0: 40,margin:isMobile ? 'auto': null}}>
                        <div className="gmap_canvas" style={{ width:mapWidth}}>
                            <iframe title="head-office" width={mapWidth} height="500" id="gmap_canvas" src={mapAddress} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                    <ContactUs />
                    <Qoutes />
                </section>
                <Footer/>
            </Loader>
        )
    }
}



export default About;