import React from 'react';
import { isMobile } from 'react-device-detect';

const ColumnTwo = () => {
    return (
        <div className="col-md-3" style={{ color: '#fff', }}>
            <h5 style={{ color: '#fff', textTransform: 'uppercase',fontWeight:'bold', fontSize: 15 ,marginTop: isMobile ? 30 :0}}>Contact GOLD SOLES</h5>
            <div style={{ marginTop:isMobile? 20: 40, fontSize: 14}}>
                <div style={{marginBottom:10}}>24 Gallinule Gait,</div>
                <div style={{marginBottom:10}}>Richardsbay 3900,</div>
                <div style={{marginBottom:10}}>KwaZulu-Natal,</div>
                <div style={{marginBottom:10}}>South Africa</div>
            </div>
        </div>
    )
}

export default ColumnTwo