import React, { Component } from 'react'
import { animateScroll,scroller } from "react-scroll";
import { Header, HeaderStrip, NavigationBar, Footer, Loader, SeoHeader } from 'components';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { ClipLoader } from 'react-spinners';

const webloan_url = "https://2870-8888.webfin.co.za/WEBLOANS/default.aspx"
const bgImage = "https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80";
class About extends Component {
    state = {
        loadingFrame:true
    }

    componentDidMount = async () =>{
        ReactGA.initialize('UA-135086987-6');
        ReactGA.pageview(window.location.pathname);
        animateScroll.scrollToTop();
    }

    scrollToContact = () => {
        return scroller.scrollTo("webloan", {
            duration: 500,
            hashSpy: true,
            delay: 100,
            smooth: true,
            offset:isMobile ? -60 : 0
        });
    }

    hideSpinner = () => {
        this.setState({
          loading: false
        });
      };

    render() {
        return (
            <Loader loading={this.state.loading}>
                {this.state.loadingFrame && 
                    <div style={{height:window.innerHeight,backgroundColor:'#000', position:'absolute', width:'100%', zIndex:999, top:0}}>
                         <div style={{ height: '100vh', flexDirection: 'column', display: 'flex', backgroundColor:'#000'}}>
                            <div style={{margin:'auto'}}>
                                <ClipLoader
                                    size={100}
                                    color={"#79611D"}
                                />
                                <h5 style={{color:'#fff', marginTop:50,textAlign:'center'}}>Loading ...</h5>
                            </div>
                        </div>
                    </div>
                    }
                <SeoHeader 
                    title="Web Loan"
                    description={`
                        Keep in touch, find a business contact or scroll down to use our online contact form.
                    `}
                    keywords="Keep in Touch, Contact, Goldsoles, Investment"
                />
                {!this.state.loadingFrame && <NavigationBar/>}
                {!this.state.loadingFrame && <Header
                    bgImage={bgImage}
                    smallTitle="WEB"
                    title="LOANS"
                    // titleTwo="GoldSole"
                    // buttonTitleOne="Learn more"
                    buttonTitleTwo="GET A LOAN"
                    onButtonTwo={this.scrollToContact}
                    imageStyle={{left:isMobile? '100%': '50%'}}
                    small
                />}
                 <section>
                 {!this.state.loadingFrame &&  <HeaderStrip 
                        title="Web Loans"
                        description={`We here to help you with your financial needs.`}
                    />}
                     <div id="webloan" class="container pt-5 pb-5">
                        <div className="mx-auto col-md-11">
                            <iframe onLoad={()=> this.setState({loadingFrame:false})} title="webloan" frameBorder="0" width="100%" height="500px" src={webloan_url}></iframe>
                        </div>
                    </div>
                    {/* <Qoutes /> */}
                </section>
                {!this.state.loadingFrame && <Footer/>}
            </Loader>
        )
    }
}



export default About;